import http from "@/api/axios.js";
/**
 * 客户管理api
 */

// 获取社保折扣系数数据
export function GetItem(customerId) {
  return http({
    url: `/api/SocialDiscount/GetItem/${customerId}`,
    method: "get",
    params: null,
  });
}

// 启用客户折扣系数
export function Enable(customerId) {
  return http({
    url: `/api/SocialDiscount/Enable/${customerId}`,
    method: "put",
    params: null,
  });
}

// 禁用客户折扣系数
export function Disable(customerId) {
  return http({
    url: `/api/SocialDiscount/Disable/${customerId}`,
    method: "put",
    params: null,
  });
}

// 修改折扣系数数据
export function Edits(customerId, data) {
  return http({
    url: `/api/SocialDiscount/Edits/${customerId}`,
    method: "post",
    params: null,
    data: data
  });
}
