<template>
  <div id="discount-factor" ref="discountFactor">
    <ul class="tabs">
      <li class="tab active-tab">社保服务费</li>
    </ul>
    <div class="discount-container">
      <div class="discount-switch-control">
        <div class="discount-setting-title">是否启用折扣</div>
        <a-switch
          class="discount-switch"
          v-model:checked="discountUseable"
          :loading="discountUseableLoading"
          @change="discountUseableChange"
        />
      </div>
      <div class="discount-details" v-show="discountUseable">
        <!-- 单城市服务费折扣 -->
        <div class="discount-setting" id="singleCity" ref="singleCity">
          <div class="discount-setting-header">
            <div class="discount-setting-header-left">
              <div class="discount-setting-title">单城市服务费折扣</div>
              <a-tooltip
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                placement="right"
                trigger="hover"
                overlayClassName="overlay-class single-city-class"
              >
                <template #title
                  >按合作服务城市网点参保员工实际人数所在区间享受不同折扣系数</template
                >
                <img
                  src="../../../assets/img/discountFactor/tips.png"
                  class="discount-setting-title-tips"
                />
              </a-tooltip>
            </div>
            <div
              class="discount-setting-header-right"
              @click="addDiscountRow('single')"
            >
              新增
            </div>
          </div>
          <div class="setting-block-body">
            <a-space
              :size="10"
              :class="[
                'discount-setting-row',
                {
                  'discount-setting-row-error':
                    singleCityErrorRow.indexOf(index) != -1,
                },
              ]"
              v-for="(item, index) in singleCitySetting"
              :key="index"
            >
              <a-input-number
                v-model:value="item.discountFactor"
                style="width: 80px"
                :precision="2"
                :step="0.01"
                :min="0"
                :max="1"
              />
              <span class="text">：</span>
              <a-input-number
                v-model:value="item.populationLowerLimit"
                :min="1"
                :max="999999"
                string-mode
                :precision="0"
                style="width: 120px"
                :disabled="index != 0"
                v-if="item.beforeRelational != 0"
              />
              <a-select
                v-model:value="item.beforeRelational"
                style="width: 65px"
              >
                <a-select-option :value="1">&lt;</a-select-option>
                <a-select-option :value="2">&lt;=</a-select-option>
                <a-select-option :value="0" v-if="index == 0"
                  >无</a-select-option
                >
              </a-select>
              <span class="text">人数</span>
              <a-select
                v-model:value="item.afterRelational"
                style="width: 65px"
              >
                <a-select-option :value="1">&lt;</a-select-option>
                <a-select-option :value="2">&lt;=</a-select-option>
                <a-select-option
                  :value="0"
                  v-if="index == singleCitySetting.length - 1"
                  >无</a-select-option
                >
              </a-select>
              <a-input-number
                v-model:value="item.populationUpperLimit"
                :min="1"
                :max="999999"
                string-mode
                :precision="0"
                style="width: 120px"
                v-if="item.afterRelational != 0"
                @change="
                  (val) => populationUpperLimitChange('single', index, val)
                "
              />
              <i
                class="delete-btn"
                v-if="index != 0"
                @click="openDeleteModal('single', index)"
              ></i>
            </a-space>
          </div>
        </div>
        <!-- 整体城市服务费折扣 -->
        <div class="discount-setting" id="multiCity" ref="multiCity">
          <div class="discount-setting-header">
            <div class="discount-setting-header-left">
              <div class="discount-setting-title">整体城市服务费折扣</div>
              <a-tooltip
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                placement="right"
                trigger="hover"
                overlayClassName="overlay-class multi-city-class"
              >
                <template #title
                  >按所有合作服务城市网点参保员工实际人数所在区间享受不同折扣系数</template
                >
                <img
                  src="../../../assets/img/discountFactor/tips.png"
                  class="discount-setting-title-tips"
                />
              </a-tooltip>
            </div>
            <div
              class="discount-setting-header-right"
              @click="addDiscountRow('multi')"
            >
              新增
            </div>
          </div>
          <div class="setting-block-body">
            <a-space
              :size="10"
              :class="[
                'discount-setting-row',
                {
                  'discount-setting-row-error':
                    multiCityErrorRow.indexOf(index) != -1,
                },
              ]"
              v-for="(item, index) in multiCitySetting"
              :key="index"
            >
              <a-input-number
                v-model:value="item.discountFactor"
                style="width: 80px"
                :precision="2"
                :step="0.01"
                :min="0"
                :max="1"
              />
              <span class="text">：</span>
              <a-input-number
                v-model:value="item.populationLowerLimit"
                :min="1"
                :max="999999"
                string-mode
                :precision="0"
                style="width: 120px"
                :disabled="index != 0"
                v-if="item.beforeRelational != 0"
              />
              <a-select
                v-model:value="item.beforeRelational"
                style="width: 65px"
              >
                <a-select-option :value="1">&lt;</a-select-option>
                <a-select-option :value="2">&lt;=</a-select-option>
                <a-select-option :value="0" v-if="index == 0"
                  >无</a-select-option
                >
              </a-select>
              <span class="text">人数</span>
              <a-select
                v-model:value="item.afterRelational"
                style="width: 65px"
              >
                <a-select-option :value="1">&lt;</a-select-option>
                <a-select-option :value="2">&lt;=</a-select-option>
                <a-select-option
                  :value="0"
                  v-if="index == multiCitySetting.length - 1"
                  >无</a-select-option
                >
              </a-select>
              <a-input-number
                v-model:value="item.populationUpperLimit"
                :min="1"
                :max="999999"
                string-mode
                :precision="0"
                style="width: 120px"
                v-if="item.afterRelational != 0"
                @change="
                  (val) => populationUpperLimitChange('multi', index, val)
                "
              />
              <i
                class="delete-btn"
                v-if="index != 0"
                @click="openDeleteModal('multi', index)"
              ></i>
            </a-space>
          </div>
        </div>
      </div>
      <a-button
        class="save-btn"
        type="primary"
        :loading="saving"
        @click="validData"
        v-show="discountUseable"
        >保存配置</a-button
      >
      <div class="spinning-modal" v-if="spinning">
        <a-spin :spinning="spinning" />
      </div>
    </div>
    <a-modal
      class="delete-modal"
      :width="400"
      :centered="true"
      v-model:visible="deleteModal"
      :getContainer="() => $refs.discountFactor"
    >
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="deleteLoading"
          @click="deleteDiscount"
          >确认</a-button
        >
        <a-button key="back" @click="deleteModal = false">取消</a-button>
      </template>
      <div class="delete-modal-body">
        <i class="delete-icon"></i>
        <p class="delete-title">是否确认进行删除？</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import { GetItem, Enable, Disable, Edits } from "@/api/customerManagement";
import { Modal, message } from "ant-design-vue";
export default {
  setup() {
    const state = reactive({
      customerId: null,
      discountUseable: false,
      discountUseableLoading: false,
      singleCitySetting: [],
      singleCityDefault: [
        {
          discountFactor: 1.0,
          populationLowerLimit: "",
          beforeRelational: 0,
          afterRelational: 1,
          populationUpperLimit: "50",
        },
        {
          discountFactor: 0.95,
          populationLowerLimit: "50",
          beforeRelational: 2,
          afterRelational: 1,
          populationUpperLimit: "200",
        },
        {
          discountFactor: 0.9,
          populationLowerLimit: "200",
          beforeRelational: 2,
          afterRelational: 1,
          populationUpperLimit: "500",
        },
        {
          discountFactor: 0.85,
          populationLowerLimit: "500",
          beforeRelational: 2,
          afterRelational: 1,
          populationUpperLimit: "1000",
        },
        {
          discountFactor: 0.8,
          populationLowerLimit: "100",
          beforeRelational: 2,
          afterRelational: 0,
          populationUpperLimit: "",
        },
      ],
      singleCityErrorRow: [],
      multiCitySetting: [],
      multiCityDefault: [
        {
          discountFactor: 1.0,
          populationLowerLimit: "",
          beforeRelational: 0,
          afterRelational: 1,
          populationUpperLimit: "300",
        },
        {
          discountFactor: 0.95,
          populationLowerLimit: "300",
          beforeRelational: 2,
          afterRelational: 1,
          populationUpperLimit: "1000",
        },
        {
          discountFactor: 0.9,
          populationLowerLimit: "1000",
          beforeRelational: 2,
          afterRelational: 1,
          populationUpperLimit: "3000",
        },
        {
          discountFactor: 0.85,
          populationLowerLimit: "3000",
          beforeRelational: 2,
          afterRelational: 1,
          populationUpperLimit: "5000",
        },
        {
          discountFactor: 0.8,
          populationLowerLimit: "5000",
          beforeRelational: 2,
          afterRelational: 0,
          populationUpperLimit: "",
        },
      ],
      multiCityErrorRow: [],
      deleteModal: false,
      deleteLoading: false,
      currentDeleteType: "", // 当前操作删除的类型（单城市/整体）
      currentDeleteIndex: null, // 当前操作删除的rowindex
      spinning: false,
      saving: false,
    });

    const route = useRoute();

    // 开关折扣switch
    const discountUseableChange = (val) => {
      if (val) {
        enableDiscount();
      } else {
        disableDiscount();
      }
    };

    // 启用
    const enableDiscount = async () => {
      if (!state.customerId) {
        message.error("请先选择客户");
        return;
      }
      state.discountUseableLoading = true;
      state.spinning = true;
      await Enable(state.customerId)
        .then(() => {
          state.discountUseableLoading = false;
          state.spinning = false;
          getSocialDiscount();
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
          state.discountUseable = !state.discountUseable;
          state.discountUseableLoading = false;
          state.spinning = false;
        });
    };

    // 禁用
    const disableDiscount = async () => {
      if (!state.customerId) {
        message.error("请先选择客户");
        return;
      }
      state.discountUseableLoading = true;
      state.spinning = true;
      await Disable(state.customerId)
        .then(() => {
          state.discountUseableLoading = false;
          state.spinning = false;
          getSocialDiscount();
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
          state.discountUseable = !state.discountUseable;
          state.discountUseableLoading = false;
          state.spinning = false;
        });
    };

    // 新增折扣系数（single--单城市，multi--多城市）
    const addDiscountRow = (type) => {
      let newDiscount = {
        discountFactor: null,
        populationLowerLimit:
          state[`${type}CitySetting`][state[`${type}CitySetting`].length - 1]
            .populationUpperLimit,
        beforeRelational: 2,
        afterRelational: 1,
        populationUpperLimit: "",
      };
      if (
        state[`${type}CitySetting`][state[`${type}CitySetting`].length - 1]
          .afterRelational == 0
      ) {
        state[`${type}CitySetting`][
          state[`${type}CitySetting`].length - 1
        ].afterRelational = 1;
      }
      state[`${type}CitySetting`].push(newDiscount);
    };

    // 删除折扣系数弹窗
    const openDeleteModal = (type, index) => {
      state.deleteModal = true;
      state.currentDeleteType = type;
      state.currentDeleteIndex = index;
    };

    // 删除折扣系数
    const deleteDiscount = async () => {
      state.deleteLoading = true;
      state[`${state.currentDeleteType}CitySetting`].splice(
        state.currentDeleteIndex,
        1
      );
      if (
        state[`${state.currentDeleteType}CitySetting`][
          state[`${state.currentDeleteType}CitySetting`].length - 1
        ].afterRelational != 0
      ) {
        state[`${state.currentDeleteType}CitySetting`][
          state[`${state.currentDeleteType}CitySetting`].length - 1
        ].afterRelational = 0;
      }
      state.deleteLoading = false;
      state.deleteModal = false;
      state.currentDeleteType = "";
      state.currentDeleteIndex = null;
    };

    // 折扣系数规则--后一条最小人数跟随前一条最大人数
    const populationUpperLimitChange = (type, index, val) => {
      state[`${type}CitySetting`][index + 1].populationLowerLimit = val;
    };

    // 获取折扣系数
    const getSocialDiscount = async () => {
      if (!state.customerId) {
        message.error("请先选择客户");
        return;
      }
      state.spinning = true;
      await GetItem(state.customerId)
        .then((res) => {
          handleData(res.data);
          state.spinning = false;
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
          state.spinning = false;
        });
    };

    const handleData = (data) => {
      state.discountUseable = data.isEnable;
      try {
        for (let i of data.scopes) {
          if (i.scope === 1) {
            if (!i.items || i.items.length <= 0) {
              state.singleCitySetting = state.singleCityDefault;
              return;
            }
            state.singleCitySetting = i.items;
          } else if (i.scope === 0) {
            if (!i.items || i.items.length <= 0) {
              state.multiCitySetting = state.multiCityDefault;
              return;
            }
            state.multiCitySetting = i.items;
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    // 保存配置 -> 验证空白区/重叠区
    const validData = () => {
      state.singleCityErrorRow = [];
      state.multiCityErrorRow = [];
      const singleCityErrorValid = () => {
        for (let [sidx, si] of state.singleCitySetting.entries()) {
          if (sidx + 1 === state.singleCitySetting.length) {
            // 最后一行
            if (
              !si.discountFactor ||
              (!si.populationUpperLimit && si.afterRelational != 0) ||
              (!nsi.populationLowerLimit && nsi.beforeRelational != 0) ||
              parseInt(si.populationUpperLimit) <
                parseInt(si.populationLowerLimit)
            ) {
              message.error("规则有误");
              state.singleCityErrorRow = [sidx];
              return;
            }
            return;
          }
          let nsi = state.singleCitySetting[sidx + 1];

          // 当前行的'右符号'与下一行的'左符号'都是1（小于号）
          if (
            si.afterRelational === nsi.beforeRelational &&
            si.afterRelational === 1
          ) {
            // 下一行的最小值必须等于上一行的最大值-1，否则有空白区或重复区
            if (
              parseInt(si.populationUpperLimit) - 1 >
              parseInt(nsi.populationLowerLimit)
            ) {
              // 有重复区间
              message.error("折扣系数区间有重叠");
              state.singleCityErrorRow = [sidx, sidx + 1];
              return;
            } else if (
              parseInt(si.populationUpperLimit) - 1 <
              parseInt(nsi.populationLowerLimit)
            ) {
              // 有空白区间
              message.error("折扣系数区间有空白");
              state.singleCityErrorRow = [sidx, sidx + 1];
              return;
            }
          }
          // 当前行的'右符号'与下一行的'左符号'都是2（小于等于号）
          else if (
            si.afterRelational === nsi.beforeRelational &&
            si.afterRelational === 2
          ) {
            // 下一行的最小值必须等于上一行的最大值+1，否则有空白区或重复区
            if (
              parseInt(si.populationUpperLimit) + 1 >
              parseInt(nsi.populationLowerLimit)
            ) {
              // 有重复区间
              message.error("折扣系数区间有重叠");
              state.singleCityErrorRow = [sidx, sidx + 1];
              return;
            } else if (
              parseInt(si.populationUpperLimit) + 1 <
              parseInt(nsi.populationLowerLimit)
            ) {
              // 有空白区间
              message.error("折扣系数区间有空白");
              state.singleCityErrorRow = [sidx, sidx + 1];
              return;
            }
          }
          // 当前行的'右符号'是1（小于号），下一行的'左符号'是2（小于等于号）
          // 当前行的'右符号'是2（小于等于号），下一行的'左符号'是1（小于号）
          else if (
            (si.afterRelational === 1 && nsi.beforeRelational === 2) ||
            (si.afterRelational === 2 && nsi.beforeRelational === 1)
          ) {
            // 下一行的最小值必须等于上一行的最大值，否则有空白区或重复区
            if (
              parseInt(si.populationUpperLimit) >
              parseInt(nsi.populationLowerLimit)
            ) {
              // 有重复区间
              message.error("折扣系数区间有重叠");
              state.singleCityErrorRow = [sidx, sidx + 1];
              return;
            } else if (
              parseInt(si.populationUpperLimit) <
              parseInt(nsi.populationLowerLimit)
            ) {
              // 有空白区间
              message.error("折扣系数区间有空白");
              state.singleCityErrorRow = [sidx, sidx + 1];
              return;
            }
          }
          if (
            !si.discountFactor ||
            (!si.populationUpperLimit && si.afterRelational != 0) ||
            (!nsi.populationLowerLimit && nsi.beforeRelational != 0) ||
            parseInt(si.populationUpperLimit) <
              parseInt(si.populationLowerLimit)
          ) {
            message.error("规则有误");
            state.singleCityErrorRow = [sidx];
            return;
          }
        }
      };
      const multiCityErrorValid = () => {
        for (let [midx, mi] of state.multiCitySetting.entries()) {
          if (midx + 1 === state.multiCitySetting.length) {
            // 最后一行
            if (
              !mi.discountFactor ||
              (!mi.populationUpperLimit && mi.afterRelational != 0) ||
              (!nmi.populationLowerLimit && nmi.beforeRelational != 0) ||
              parseInt(mi.populationUpperLimit) <
                parseInt(mi.populationLowerLimit)
            ) {
              message.error("规则有误");
              state.multiCityErrorRow = [midx];
              return;
            }
            return;
          }
          let nmi = state.multiCitySetting[midx + 1];

          // 当前行的'右符号'与下一行的'左符号'都是1（小于号）
          if (
            mi.afterRelational === nmi.beforeRelational &&
            mi.afterRelational === 1
          ) {
            // 下一行的最小值必须等于上一行的最大值-1，否则有空白区或重复区
            if (
              parseInt(mi.populationUpperLimit) - 1 >
              parseInt(nmi.populationLowerLimit)
            ) {
              // 有重复区间
              message.error("折扣系数区间有重叠");
              state.multiCityErrorRow = [midx, midx + 1];
              return;
            } else if (
              parseInt(mi.populationUpperLimit) - 1 <
              parseInt(nmi.populationLowerLimit)
            ) {
              // 有空白区间
              message.error("折扣系数区间有空白");
              state.multiCityErrorRow = [midx, midx + 1];
              return;
            }
          }
          // 当前行的'右符号'与下一行的'左符号'都是2（小于等于号）
          else if (
            mi.afterRelational === nmi.beforeRelational &&
            mi.afterRelational === 2
          ) {
            // 下一行的最小值必须等于上一行的最大值+1，否则有空白区或重复区
            if (
              parseInt(mi.populationUpperLimit) + 1 >
              parseInt(nmi.populationLowerLimit)
            ) {
              // 有重复区间
              message.error("折扣系数区间有重叠");
              state.multiCityErrorRow = [midx, midx + 1];
              return;
            } else if (
              parseInt(mi.populationUpperLimit) + 1 <
              parseInt(nmi.populationLowerLimit)
            ) {
              // 有空白区间
              message.error("折扣系数区间有空白");
              state.multiCityErrorRow = [midx, midx + 1];
              return;
            }
          }
          // 当前行的'右符号'是1（小于号），下一行的'左符号'是2（小于等于号）
          // 当前行的'右符号'是2（小于等于号），下一行的'左符号'是1（小于号）
          else if (
            (mi.afterRelational === 1 && nmi.beforeRelational === 2) ||
            (mi.afterRelational === 2 && nmi.beforeRelational === 1)
          ) {
            // 下一行的最小值必须等于上一行的最大值，否则有空白区或重复区
            if (
              parseInt(mi.populationUpperLimit) >
              parseInt(nmi.populationLowerLimit)
            ) {
              // 有重复区间
              message.error("折扣系数区间有重叠");
              state.multiCityErrorRow = [midx, midx + 1];
              return;
            } else if (
              parseInt(mi.populationUpperLimit) <
              parseInt(nmi.populationLowerLimit)
            ) {
              // 有空白区间
              message.error("折扣系数区间有空白");
              state.multiCityErrorRow = [midx, midx + 1];
              return;
            }
          }
          if (
            !mi.discountFactor ||
            (!mi.populationUpperLimit && mi.afterRelational != 0) ||
            (!nmi.populationLowerLimit && nmi.beforeRelational != 0) ||
            parseInt(mi.populationUpperLimit) <
              parseInt(mi.populationLowerLimit)
          ) {
            message.error("规则有误");
            state.multiCityErrorRow = [midx];
            return;
          }
        }
      };
      singleCityErrorValid();
      if (state.singleCityErrorRow.length > 0) return;
      multiCityErrorValid();
      if (state.multiCityErrorRow.length > 0) return;
      EditsDiscount();
    };

    const EditsDiscount = async () => {
      let data = [];
      for (let si of state.singleCitySetting) {
        let sobj = si;
        sobj.scope = 1; // 单城市
        data.push(sobj);
      }
      for (let mi of state.multiCitySetting) {
        let mobj = mi;
        mobj.scope = 0; // 整体
        data.push(mobj);
      }
      state.spinning = true;
      await Edits(state.customerId, data)
        .then((res) => {
          if (res.data) {
            message.success("保存成功");
          }
          state.spinning = false;
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
          state.spinning = false;
        });
    };

    onMounted(() => {
      state.customerId = route.query.cuId ?? "";
      getSocialDiscount();
    });

    return {
      ...toRefs(state),
      discountUseableChange,
      enableDiscount,
      disableDiscount,
      populationUpperLimitChange,
      getSocialDiscount,
      addDiscountRow,
      openDeleteModal,
      deleteDiscount,
      validData,
    };
  },
};
</script>
<style lang="less" scoped>
#discount-factor {
  position: relative;
  background: #ffffff;
  .tabs {
    height: 50px;
    border-bottom: 1px solid #e9e9e9;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 0 0 30px;
    .tab {
      font-size: 13px;
      line-height: 17px;
      font-weight: bold;
      color: #000000;
      padding: 19px 0 11px;
      list-style: none;
      &.active-tab {
        color: #0b85e6;
        border-bottom: 2px solid #2196f3;
      }
    }
  }
  .discount-container {
    padding: 25px 30px;
    width: 800px;
    .discount-setting-title {
      font-size: 13px;
      font-weight: bold;
      line-height: 17px;
      color: #000000;
      margin-right: 10px;
    }
    .discount-switch-control {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      .discount-switch {
        margin-left: 5px;
      }
    }
    .discount-setting {
      .discount-setting-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
      }
      .discount-setting-header-left {
        display: flex;
        align-items: center;
        /deep/ .overlay-class {
          font-size: 13px;
          .ant-tooltip-inner {
            width: 222px;
            padding: 10px 10px 10px 12px;
            border-radius: 4px;
            line-height: 19px;
            background-color: rgba(0, 0, 0, 0.48);
          }
          .ant-tooltip-arrow-content {
            background-color: rgba(0, 0, 0, 0.48);
          }
        }
        /deep/ .multi-city-class {
          .ant-tooltip-inner {
            width: 234px;
          }
        }
      }
      .discount-setting-header-right {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 6px 12px;
        height: 30px;
        border: 1px solid #2196f3;
        opacity: 1;
        border-radius: 2px;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        color: #2196f3;
        text-align: center;
        cursor: pointer;
        &::before {
          content: "";
          display: inline-block;
          width: 13.2px;
          height: 13.2px;
          background: url(../../../assets/img/discountFactor/addblack.png);
          background-size: cover;
          margin-right: 4px;
        }
        &:hover {
          background: #2196f3;
          color: #ffffff;
          &::before {
            background: url(../../../assets/img/discountFactor/addwhite.png);
          }
        }
      }
      .discount-setting-title-tips {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      .discount-setting-row {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        padding: 6px;
        .text {
          color: #000000;
        }
        .delete-btn {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url(../../../assets/img/discountFactor/delete.png);
          background-size: cover;
          cursor: pointer;
        }
        &.discount-setting-row-error {
          border: 1px solid #f44336;
          border-radius: 4px;
        }
      }
    }
  }
  .my-custom {
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #000000;
  }
  /deep/ .ant-input-number {
    .my-custom();
  }
  /deep/ .ant-select-selector {
    .my-custom();
  }
  .delete-modal-body {
    display: flex;
    align-items: center;
    .delete-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../../../assets/img/discountFactor/delete.png);
      background-size: cover;
      margin-right: 8px;
    }
    .delete-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #000000;
      margin-bottom: 0;
    }
  }
  /deep/ .ant-modal-content {
    border-radius: 4px;
    .ant-modal-body {
      padding: 30px 40px;
    }
    .ant-modal-footer {
      border: none;
      padding: 10px 40px 30px;
      .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 20px;
      }
    }
  }
  .save-btn {
    margin-top: 12px;
    border-color: #2196f3;
    background: #2196f3;
    font-size: 13px;
    color: #ffffff;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    &:hover {
      background: #2196f3;
      box-shadow: 0px 4px 8px rgba(32, 150, 243, 0.16);
    }
  }
  .spinning-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
}
</style>
